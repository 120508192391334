 <template>
  <div>
    <!-- 图片展示区 -->
    <div class="displayPt">
      <!-- 公告 -->
      <div class="container msg">
					<span class="glyphicon glyphicon-volume-up"  aria-hidden="true" style="margin-left: 10px;margin-right: 10px;"></span>
					<h4>{{msg}}</h4>
		  </div>
      <!-- 轮播图 -->
      <div style="z-index:-1;">
        <el-carousel :height="hh" width="100%" indicator-position="outside">
        <el-carousel-item v-for="(img,index) in imglist" :key="index">
          <el-image style="width: 100%; height: 650px" :src=" url+img"></el-image>
        </el-carousel-item>
      </el-carousel>
      </div>
    </div>
    
    <div class="cbbfixed" style="bottom: 6rem;">
      <a class="cbbtn" @click="goTop">
        <span class="up-icon"></span>
        <span>回到顶部</span>
      </a>
      <div v-for="item in data" :key="item.id"  class="cbbtn douyin">
        <span class="dy-icon">
          <img  :src="url + item.iconUrl" alt="">
        </span>
        <span>{{item.qrCodeName}}</span>
        <div class="qrcode-box">
          <img :src="url + item.qrCodeUrl"/>
        </div>
      </div>
    </div>
    <!-- 主要内容区 -->
     <!-- 大数据管理系统 --> 
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <img class="img-responsive" src="../assets/img/img1.png" alt="大数据管理系统">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <h2 class="bigData-title">
            大数据管理系统
            <small>/ Big Data Management System</small>
          </h2>
          <p>当今最领先的响应式自助建站平台。无论您是普通互联网用户，还是专业网站制作人员，都能使用起飞页设计出最具专业水准的网站。想创建一个简单的单页式站点，还是一个专业的公司网站，亦或是一个别具一格的博客？起飞页可以满足您的所有需求。</p>
          <p>我们的流线式网页布局设计方案和可视化图文内容编辑模式让网站制作和维护成为一件轻松惬意的事。无论您是普通互联网用户，还是专业网站制作人员。</p>
          <h2 class="bigData-device">PC/PAD/Phone &nbsp; 全设备支持</h2>
          <router-link to="./contactus" class="btn btn-lg btn-block btn-info">联系我们</router-link>
        </div>
      </div>
    </div>
    <!-- 您身边的IT专家 -->
    <div id="contactUs" class="container-fuild text-center">
      <div class="container contactUs-container wow slideInUp">
        <h1>您身边的IT专家</h1>
        <h3>7x24小时提供出色的IT服务</h3>
        <router-link to="./contactus">
        <button
          class="btn btn-default btn-sm"
          onmouseleave="this.style.borderColor='#ffffff'; this.style.backgroundColor='#ffffff'; this.style.color='#3f3f3f';"
          onmouseenter="this.style.backgroundColor='transparent'; this.style.borderColor='#ffffff'; this.style.color='#ffffff';"
        >联系我们</button>
        </router-link>
        <div class="contactUs-contactWay">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 -->
    <div id="whyChooseUs" class="conatiner-fuild">
      <div class="container">
        <div class="whyChooseUs-title text-center">
          <p>为什么选择我们的服务</p>
          <p>THE REASON TO CHOOSING US</p>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-6 col-md-3 server-wrapper"
            v-for="(item,index) in serverList"
            :key="index"
          >
            <div
              class="server-block wow slideInUp"
              onmouseenter="this.style.color='#28f';this.style.borderColor='#28f'"
              onmouseleave="this.style.color='#666';this.style.borderColor='#ccc'"
            >
              <img  class="center-block" :src="item.logo" alt="logo">
              <p class="text-center">{{item.title}}</p>
              <div
                class="text-center"
                v-html="item.content"
                onmouseenter="this.style.color='#28f'"
                onmouseleave="this.style.color='#ccc'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
import {get} from '../network/request'
import {BASE_URL} from '../network/config/index'
export default {
    data() {
      return {
        data:[],
        msg:'',
        douyinurl:'',
        intervalId:null,
        serverList: [
        {
          logo: require("@/assets/img/tel.png"),
          title: "核心优势1",
          content: "<p>由专业客服提供人工服务</p>负责疑难问题和故障受理"
        },
        {
          logo: require("@/assets/img/computer.png"),
          title: "核心优势2",
          content: "<p>利用远程视频工具，提供协助</p>帮助客户进行调试、解决故障"
        },
        {
          logo: require("@/assets/img/qq.png"),
          title: "核心优势3",
          content: "<p>利用企业QQ提供在线解答</p>帮助企业快速准确解决问题和故障"
        },
        {
          logo: require("@/assets/img/skill.png"),
          title: "核心优势4",
          content: "<p>由技术支持工程师，负责问题解答</p>需求受理及故障受理"
        }
      ],
        imgs:[
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        ],
        imglist:[],
        url:BASE_URL + '/',
        hh:'650px'
      }
    },
    created(){
      this.getlunbo()
      this.getdata()
    },
    components:{
      Header,
      Footer,
    },
    mounted(){
      this.lang()
      this.getcode()
    },
    methods: {
      // 二维码
      getcode(){
        get('/ess-publicity-qrcode/listUser',{

        }).then(res=>{
          console.log(res);
          this.data = res.data.data
        })
      },
      // 回到顶部
      goTop () {
        cancelAnimationFrame(this.timer)
        const self = this
        self.timer = requestAnimationFrame(function fn () {
          const oTop = document.body.scrollTop || document.documentElement.scrollTop
          if (oTop > 0) {
            document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
            self.timer = requestAnimationFrame(fn)
          } else {
            cancelAnimationFrame(self.timer)
          }
        })
    },
      getdata(){
        get('/sys-announcement/listWithUser',{
        }).then(res=>{
          console.log(res);
          this.msg = res.data.data.announcement
        }).catch(err=>{
          console.log(err);
        })
      },
      lang(){
					// console.log(1);
					if(this.intervalId !== null){
						return;
					}
					var _this = this
					this.intervalId = setInterval(function(){
						// 获取到头的第一个字符
						var start = _this.msg.substring(0,1)
						
						// 获取到后面的所有字符
						var end = _this.msg.substring(1)
						
						// 重新拼接得到的字符串 并赋值给this.msg
						_this.msg = end + start
						
					},200)
					
				},
      getlunbo(){
        get('/sys-carousel/getCarousel/3',{
        }).then(res=>{
          console.log(res);
          if(res.data.code == 200)
          {
            for(const i of res.data.data){
              this.imglist.push(i.carouselUrl)
              // console.log(this.imglist);
            }
          }
        }).catch(err=>{
          console.log(err);
        })
      }
    }
}
</script>
<style lang="less" scoped>
.up-icon{
    margin: 5px auto;
    width: 32px;
    height: 35px;
    background: url('../assets/img/top.png') no-repeat;
}

// 二维码
.qrcode-box {
    width: 198px;
    position:relative;
    right:21rem;
    bottom:19rem;
    height: 200px;
    display: none;
}
.douyin{
  &:hover .qrcode-box{
    display: block;
    animation: fade-in 0.5s linear forwards;
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.dy-icon{
  margin: 5px auto;
  width: 32px;
  height: 35px;
  // background: url('../assets/img/douyin.png') no-repeat;

}
.cbbfixed .cbbtn span {
    display: block;
    font-size: 12px;
    text-align: center;
}
.cbbfixed .cbbtn{
    width: 105px;
    height: 85px;
    padding: 10px;
    display: block;
    // overflow: hidden;
    border-bottom: 1px solid #ddd;
    
}
.cbbfixed{
  position: fixed;
  right: 10px;
  transition: bottom ease .3s;
  // bottom: -85px;
  z-index: 3;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
}
.msg{
    height: 30px;
    // width: 100%;
		display: flex;
    color: #fff;
    margin-left: 30%;
		flex-direction: row;
		border-radius: 5px;
    position: absolute;
    z-index: 3;
		align-items: center;
		white-space:nowrap;
		overflow: hidden;
	}
  .displayPt {
    width: 100%;
    position: relative;
  }
  .el-container {
    width:100%;
    padding-top: 20px;
    .header{
      font-size: 24px;
    }
  }
  .block{
    margin: 0 auto;
    width: 80%;
  }
 /* 大数据管理系统 */
#bigData {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}
#bigData .bigData-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
#bigData p {
  font-size: 14px; /*no*/
  color: #333;
  // line-height: 2rem;
}
#bigData .bigData-device {
  margin: 50px 0 20px;
}

/* 您身边的IT专家 */
#contactUs {
  color: #fff;
  height: 400px;
  background: url("../assets/img/contact_us_bg.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  transition: all ease 0.6s;
}
#contactUs .contactUs-container {
  padding-top: 50px;
}
#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}
#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(1) {
  background: url("../assets/img/weixin.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(2) {
  background: url("../assets/img/weibo.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(3) {
  background: url("../assets/img/twitter.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
/* 为什么选择我们 */
#whyChooseUs {
  padding: 100px;
}
#whyChooseUs .whyChooseUs-title {
  margin-bottom: 50px;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
  font-size: 25px;
  font-weight: 500;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
  font-size: 14px;
}
#whyChooseUs .server-block {
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-bottom: 5px solid #ccc;
}
#whyChooseUs .server-block img {
  width: 48px;
  height: 48px;
}
#whyChooseUs .server-block > p {
  font-size: 20px;
  margin: 30px 0;
}
#whyChooseUs .server-block > div {
  color: #ccc;
}
</style>

